import * as React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/Layout";
import styled from "styled-components";

const H1 = styled.h1`
  font-size: 3.5rem;
  margin: 2rem auto 2.5rem;
`;

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Layout>
        <H1>Page not found!</H1>
        <h2>It looks like the page you're looking for doesn't exist.</h2>
        <Link to="/">Back to Home</Link>
      </Layout>
    </main>
  );
};

export default NotFoundPage;
